import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import { MenuItem, NativeSelect, Select } from "@mui/material";
import axiosInstance from "../config/AxiosConfig";
import { AuthContext } from "../auth/ContextAuthentication";

const EditGroundModal = ({
  ground,
  handleClose,
  handleUpdate,
  editManagersName,
}) => {
  const { groundData, setGroundData } = useContext(AuthContext);
  const [editedGround, setEditedGround] = useState({ ...ground });
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [updatedMembers, setUpdatedMembers] = useState([]);
  const [latitude, setLatitude] = useState(
    editedGround?.loc?.coordinates[1] || ""
  );
  const [longitude, setLongitude] = useState(
    editedGround?.loc?.coordinates[0] || ""
  );

  useEffect(() => {
    if (editManagersName && editManagersName?.length > 0) {
      const updatedManagers = editManagersName.filter(
        (manager) => manager.fullName !== undefined
      );
      setUpdatedMembers(updatedManagers);
    }
  }, [setUpdatedMembers]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle checkboxes separately
    const fieldValue = type === "checkbox" ? checked : value;

    setEditedGround((prevData) => ({
      ...prevData,
      [name]: fieldValue,
    }));
  };

  const handleUpdateClick = async () => {
    let coordinates = [];
    parseInt(longitude);
    parseInt(latitude);

    coordinates.push(longitude);
    coordinates.push(latitude);

    let data = {};
    data = { ...editedGround, coordinates };
    try {
      setIsUpdating(true);
      // Perform the update action using the editedGround data
      // http://localhost:5000/api/admin/updateground
      // await axios.put(`https://api.maidanofficial.com/api/admin/updateground/${editedGround._id}`,
      const response = await axiosInstance.put(
        `api/admin/updateGround/${editedGround._id}`,
        data
      );

      // Call the callback function to handle updating data
      handleUpdate(editedGround);

      // Set a success message
      setUpdateStatus({
        message: "Ground updated successfully",
        success: true,
      });
      const groundResponse = await axiosInstance.get("api/admin/getgrounds");
      const updatedGroundData = groundResponse.data.grounds; // Assuming response.data is an array

      setGroundData(updatedGroundData);
    } catch (error) {
      console.error("Error updating ground:", error);
      setUpdateStatus({
        message: "Failed to add ground, some fields are empty",
        success: false,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Ground</DialogTitle>
      <DialogContent>
        {/* {editedGround?.owner?._id} */}
        <form>
          {/* Render ground input fields for editing */}
          <Select
            // required
            id={editedGround?.owner?._id}
            name="owner"
            value={
              editedGround?.owner?._id
                ? editedGround?.owner?._id
                : editedGround?.owner
            }
            onChange={handleInputChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px",
              width: "26%",
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Set your desired max height
                },
              },
            }}
          >
            <MenuItem value={editedGround?.owner} disabled>
              {"Select Ground Owner"}
            </MenuItem>
            {updatedMembers?.map((manager) => (
              <MenuItem key={manager._id} value={manager._id}>
                {manager.fullName}
              </MenuItem>
            ))}
          </Select>

          <TextField
            required
            id="name"
            name="name"
            label="Ground Name"
            variant="outlined"
            value={editedGround?.name}
            onChange={handleInputChange}
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="pricePerBookingDay"
            name="pricePerBookingDay"
            label="Price per Booking Day"
            variant="outlined"
            type="number"
            value={editedGround?.price?.perBooking?.day}
            onChange={handleInputChange}
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="pricePerBookingNight"
            name="pricePerBookingNight"
            label="Price per Booking Night"
            variant="outlined"
            type="number"
            value={editedGround?.price?.perBooking?.night}
            onChange={handleInputChange}
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="noOfPlayers"
            name="noOfPlayers"
            label="No Of Players"
            variant="outlined"
            type="number"
            value={editedGround?.noOfPlayers}
            onChange={handleInputChange}
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="noOfFields"
            name="noOfFields"
            label="No Of Fields"
            variant="outlined"
            type="number"
            value={editedGround?.noOfFields}
            onChange={handleInputChange}
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />
          <div style={{ display: "flex" }}>
            <TextField
              required
              id="dimensions"
              name="dimensions"
              label="Dimensions"
              variant="outlined"
              value={editedGround?.dimensions}
              onChange={handleInputChange}
              margin="normal"
              sx={{
                margin: "10px ",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                id="longitude"
                name="coordinates"
                label="Longitude"
                variant="outlined"
                type="number"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                margin="normal"
                sx={{
                  margin: "10px ",
                }}
              />
              {longitude > 99 ? (
                <span
                  style={{ marginLeft: "10px", fontSize: "10px", color: "red" }}
                >
                  Please enter a valid longitude between -99 and 99
                </span>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                id="latitude"
                name="coordinates"
                label="Latitude"
                variant="outlined"
                type="number"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                margin="normal"
                sx={{
                  margin: "10px ",
                }}
              />
              {latitude > 99 ? (
                <span
                  style={{ marginLeft: "10px", fontSize: "10px", color: "red" }}
                >
                  Please enter a valid latitude between -99 and 99
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <TextField
            required
            id="address"
            name="address"
            label="Address"
            variant="outlined"
            value={editedGround?.address}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="location"
            name="location"
            label="Location"
            variant="outlined"
            value={editedGround?.location}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="city"
            name="city"
            label="City"
            variant="outlined"
            value={editedGround?.city}
            onChange={handleInputChange}
            margin="normal"
            sx={{
              margin: "10px ",
            }}
          />

          {/* <TextField
            required
            id="flood lights"
            name="Flood Lights"
            label="Flood Lights"
            variant="outlined"
            value={editedGround.properties.floodLights}
            onChange={handleInputChange}
            
            margin="normal"
          /> */}
          {/* <TextField
            required
            id="restrooms"
            name="restrooms"
            label="Restrooms"
            variant="outlined"
            value={editedGround.properties.restrooms}
            onChange={handleInputChange}
            
            margin="normal"
          /> */}
          {/* <TextField
            required
            id="parkingArea"
            name="parkingArea"
            label="parkingArea"
            variant="outlined"
            value={editedGround.properties.parkingArea}
            onChange={handleInputChange}
            
            margin="normal"
          /> */}
          {/* <TextField
            required
            id="securityCameras"
            name="securityCameras"
            label="securityCameras"
            variant="outlined"
            value={editedGround.properties.securityCameras}
            onChange={handleInputChange}
            
            margin="normal"
          />
          <TextField
            required
            id="seatingArea"
            name="seatingArea"
            label="seatingArea"
            variant="outlined"
            value={editedGround.properties.seatingArea}
            onChange={handleInputChange}
            
            margin="normal"
          />
          <TextField
            required
            id="footballs"
            name="footballs"
            label="footballs"
            variant="outlined"
            value={editedGround.properties.footballs}
            onChange={handleInputChange}
            
            margin="normal"
          /> */}

          {/* Snackbar for displaying the update status message */}
          {updateStatus !== null && (
            <Box mt={2}>
              <Snackbar
                open={true}
                autoHideDuration={5000}
                onClose={() => setUpdateStatus(null)}
              >
                <Alert
                  severity={updateStatus.success ? "success" : "error"}
                  onClose={() => setUpdateStatus(null)}
                >
                  {updateStatus.message}
                </Alert>
              </Snackbar>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateClick}
          disabled={isUpdating}
        >
          {isUpdating ? "Updating..." : "Update Ground"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          disabled={isUpdating}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroundModal;
