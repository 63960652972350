// authContext.js
import React, { createContext, useEffect, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [adminMail, setAdminMail] = useState('');
  const [forgetPassword, setforgetPassword] = useState(false)
  const [beingSend, setBeingSend] = useState(false);
  const [open, setOpen] = useState(false);
  const [accessToken, setAccessToken] = useState('')
  const [playerData, setPlayerData] = useState([])
  const [groundData, setGroundData] = useState([])
  const [matchData, setMatchData] = useState([])
  
  
  useEffect(() => {
    const storedAccessToken = localStorage.getItem('access_token');
    // const accessToken = localStorage.getItem('access_token');
    if (storedAccessToken ) {
      setAccessToken(storedAccessToken);
      setAuthenticated(true);
    }
  }, []);

  

  return (
    <AuthContext.Provider value={{
      authenticated,
      setAuthenticated,
      adminMail,
      setAdminMail,
      setforgetPassword,
      forgetPassword,
      setBeingSend,
      beingSend,
      setAccessToken,
      accessToken,
      playerData,
      setPlayerData,
      groundData,
      setGroundData,
      matchData,
      setMatchData,
      open,
      setOpen
      

    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
