import React, { useContext, useEffect, useState } from "react";
import GroundTable from "../component/GroundTable";
import { AuthContext } from "../auth/ContextAuthentication";
import axiosInstance from "../config/AxiosConfig";
import { FaPlus } from "react-icons/fa";
import AddGroundModal from "../component/AddGroundModal"; // Import the modal component

const GroundProfile = () => {
  const headerData = [
    "Name",
    "No of Players",
    "No of Feilds",
    "Dimensions",
    "City",
    "IsDeleted",
  ];
  const { setGroundData } = useContext(AuthContext);
  const [managersName, setManagersName] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [newGroundData, setNewGroundData] = useState({
    name: "",
    owner: "",
    durationDay: 0,
    durationNight: 0,
    pricePerBookingDay: 0,
    pricePerBookingNight: 0,
    perPlayer: 0,
    noOfPlayers: 0,
    noOfFields: 0,
    dimensions: "",
    floodLights: false,
    restRooms: false,
    parkingArea: false,
    securityCameras: false,
    seatingArea: false,
    footballs: false,
    bibs: false,
    address: "",
    location: "",
    city: "",
    images: [],
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroundData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveGround = async () => {
    try {
      const response = await axiosInstance.get("/api/admin/getGrounds");
      setGroundData(response.data.grounds);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSaveGround = async () => {
  //   try {

  //     const response = await axios.get('http://localhost:5000/api/admin/getGrounds');
  //     setGroundData(response.data.grounds);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   const fetchApi = async () => {
  //     try {
  //       const response = await axiosInstance.get("/api/admin/getGrounds");
  //       console.log("get Owner name", response);
  //       setGroundData(response.data.grounds);
  //       console.log(response.data.grounds);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchApi();
  // }, []);

  // staging testing
  // https://api.staging.maidanofficial.com
  //api.maidanofficial.com
  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axiosInstance.get("api/admin/getGrounds");
        setGroundData(response.data.grounds);
      } catch (error) {
        console.error(error);
      }
    };

    fetchApi();
  }, []);

  return (
    <>
      <GroundTable headerData={headerData} />

      <button onClick={handleShowModal}>
        <FaPlus className="cursor-pointer text-3xl text-green-800 fixed bottom-12 right-4" />
      </button>

      {/* Use the AddGroundModal component */}
      <AddGroundModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveGround}
        newGroundData={newGroundData}
        handleInputChange={handleInputChange}
        setNewGroundData={setNewGroundData}
        managersName={managersName}
        setManagersName={setManagersName}
      />
    </>
  );
};

export default GroundProfile;
