import axios from "axios";

let isSecuredSsl = true;
let baseUrl = isSecuredSsl
  ? process.env.REACT_APP_API_URL
  : "http://35.155.14.242/api/";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const bearerToken = localStorage.getItem("access_token");

  if (bearerToken) {
    config.headers.Authorization = `Bearer ${bearerToken}`;
  }

  return config;
});

export default axiosInstance;
