import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, AuthContext } from './auth/ContextAuthentication';
import Login from './pages/Login';
import UpdatePassword from './pages/UpdatePassword';
import GroundProfile from './pages/GroundProfile';
import PlayersProfile from './pages/PlayersProfile';
import MatchProfile from './pages/MatchProfile';
import Dashboard from './pages/Dashboard';
import SideNav from './component/SideNav';
import TopNav from './component/TopNav';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

const AppContent = () => {
  const { authenticated } = useContext(AuthContext);
  

  return (
    <div className="flex">
      {/* Conditionally render SideNav and TopNav */}
      {authenticated && (
        <>
          <SideNav />
          
            <TopNav />
          
        </>
      )}
      <Routes>
        <Route path="/" element={authenticated ? <Dashboard /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/playersprofile" element={authenticated ? <PlayersProfile /> : <Login />} />
        <Route path="/groundprofile" element={authenticated ? <GroundProfile /> : <Login />} />
        <Route path="/updatepassword" element={authenticated ? <UpdatePassword /> : <Login />} />
        <Route path="/matchprofile" element={authenticated ? <MatchProfile /> : <Login />} />
       
      </Routes>
    </div>
  );
};

export default App;
