// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-container {
  height: 400px;
  max-width: 600px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
}
.alert-message {
  max-width: 600px;
  position: absolute;
  bottom: 15px;
  /* left: 20%; */
  text-align: center;
  padding: 0 auto;
}
@media (max-width: 767px) {
  .update-container {
    max-height: 350px;
    max-width: 350px; 
    align-items: flex-start;
    margin-top: 3rem;
    box-shadow: none
  }
}

/* Media query for screens between 768px and 1023px (e.g., tablets) */
@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    height: 50vh; 
  }
}

/* Media query for screens larger than 1023px (e.g., desktops) */
@media (min-width: 1024px) {
 
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/updatePassword.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,qDAAqD;EACrD,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB;EACF;AACF;;AAEA,qEAAqE;AACrE;EACE;IACE,YAAY;EACd;AACF;;AAEA,gEAAgE;AAChE;;AAEA","sourcesContent":[".update-container {\r\n  height: 400px;\r\n  max-width: 600px;\r\n  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;\r\n  padding: 10px 40px;\r\n  position: relative;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: 80px auto;\r\n}\r\n.alert-message {\r\n  max-width: 600px;\r\n  position: absolute;\r\n  bottom: 15px;\r\n  /* left: 20%; */\r\n  text-align: center;\r\n  padding: 0 auto;\r\n}\r\n@media (max-width: 767px) {\r\n  .update-container {\r\n    max-height: 350px;\r\n    max-width: 350px; \r\n    align-items: flex-start;\r\n    margin-top: 3rem;\r\n    box-shadow: none\r\n  }\r\n}\r\n\r\n/* Media query for screens between 768px and 1023px (e.g., tablets) */\r\n@media (min-width: 768px) and (max-width: 1023px) {\r\n  .container {\r\n    height: 50vh; \r\n  }\r\n}\r\n\r\n/* Media query for screens larger than 1023px (e.g., desktops) */\r\n@media (min-width: 1024px) {\r\n \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
