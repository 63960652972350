import React, { useContext, useState } from "react";
import { AuthContext } from "../auth/ContextAuthentication";
import axiosInstance from "../config/AxiosConfig";

const VerifyOtp = (props) => {
  // const navigate = useNavigate()
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errMessage, setErrMessage] = useState(false);
  const [err, setErr] = useState(false);
  const { setforgetPassword, beingSend, forgetPassword, setBeingSend } =
    useContext(AuthContext);
  const email = props.email;

  const handleVerifyOTP = async () => {
    if (password !== confirmPassword) {
      setErr(!err);
      setErrMessage("confirm password does not match");
      console.log("confirm password does not match");
      setTimeout(() => {
        setErr(false);
      }, 3000);
      return; // Exit the function to prevent further execution
    }
    try {
      const response = await axiosInstance.post("api/admin/createpassword", {
        email,
        otp,
        password,
      });
      if (response.data.status === true) {
        console.log("OTP verified and password updated successfully ");
        setErr(!err);
        setErrMessage("OTP verified and password updated successfully");
        setTimeout(() => {
          setErr(false);
        }, 3000);
        setConfirmPassword("");
        setPassword("");
        setOtp("");
        setBeingSend(!beingSend);
        setforgetPassword(!forgetPassword);
        return;
      }
      if (response.data.status === false) {
        console.log("Invalid OTP");
        setErr(!err);
        setErrMessage("Invalid OTP");
        setTimeout(() => {
          setErr(false);
        }, 3000);
      }
    } catch (error) {
      console.log(
        "An error occurred during OTP verification and password update"
      );
      setErr(!err);
      setErrMessage(
        "An error occurred during OTP verification and password update"
      );
      setTimeout(() => {
        setErr(false);
      }, 3000);
    }
  };
  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-center uppercase from-neutral-800 font-bold">
        Forget Password
      </h1>
      <div>
        <label
          htmlFor="OTP"
          className="block mb-1 text-sm font-medium leading-6 text-gray-900"
        >
          Enter Your OTP :
        </label>

        <input
          type="number"
          name="OTP"
          if="OTP"
          placeholder="Enter your OTP"
          onChange={(event) => setOtp(event.target.value)}
          className="block w-full px-3  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
        />
      </div>
      <div>
        <label
          htmlFor="Password"
          className="block mb-1 text-sm font-medium leading-6 text-gray-900"
        >
          Enter new password :
        </label>
        <input
          type="password"
          id="password"
          htmlFor="password"
          placeholder="Enter new password"
          onChange={(event) => setPassword(event.target.value)}
          className="block w-full px-3  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
        />
      </div>

      <div>
        <label
          htmlFor="ConfirmPassword"
          className="block mb-1 text-sm font-medium leading-6 text-gray-900"
        >
          Confirm Password :
        </label>
        <input
          type="password"
          id="ConfirmPassword"
          htmlFor="ConfirmPassword"
          placeholder="Confirm Password"
          onChange={(event) => setConfirmPassword(event.target.value)}
          className="block w-full px-3  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
        />
      </div>
      <button
        style={{ backgroundColor: "#0C7240" }}
        onClick={() => handleVerifyOTP()}
        className="flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Verify
      </button>
      {err ? (
        <div className="flex w-full justify-center rounded-md my-3  px-3 py-1.5 text-sm font-light leading-6 text-white bg-[#ff0033]">
          {errMessage}
        </div>
      ) : (
        " "
      )}
    </div>
  );
};

export default VerifyOtp;
