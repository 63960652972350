import React, { useContext, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEye, FaRegEdit } from "react-icons/fa";

import {
  IconButton,
  Button,
  CircularProgress,
  Typography,
  Alert,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import PlayerProfileModal from "./PlayerProfileModal";
import EditUserModal from "./EditUserModal";
import { AuthContext } from "../auth/ContextAuthentication";
// import TextField from '@mui/material/TextField';
import { MenuItem, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CircleLoader from "./CircleLoader";
import Snackbar from "@mui/material/Snackbar";
import axiosInstance from "../config/AxiosConfig";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const TableData = (props) => {
  const headerData = props.headerData;
  const { playerData, setPlayerData } = useContext(AuthContext);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [suspend, setSuspend] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const [searchQuery, setSearchQuery] = useState("");
  const [city, setCity] = useState("All");
  const [positionFilter, setPositionFilter] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(null);
  useEffect(() => {
    // Filter the data based on the search query, city, and position
    const filtered = playerData.filter((data) => {
      const attributes = data.attributes || {};
      //console.log(attributes.position);
      return (
        data.fullName &&
        data.fullName.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (city === "All" || data.city === city) &&
        (positionFilter === "All" || attributes.position === positionFilter)
      );
    });
    setFilteredData(filtered);
  }, [searchQuery, city, positionFilter, playerData]);
  // Function to handle the search input change
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
    // console.log(city);
  };
  const handlePositionFilter = (event) => {
    setPositionFilter(event.target.value);
  };
  // Function to open the modal with player details
  const handleViewClick = (player) => {
    setSelectedPlayer(player);
  };
  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedPlayer(null);
  };
  // Function to open the edit modal
  const handleEditClick = (user) => {
    setEditUser(user);
  };
  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setEditUser(null);
  };
  // Function to open the delete confirmation modal
  const handleDeleteClick = (itemId, suspendValue) => {
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
    setSuspend(!suspendValue);
  };
  // Function to close the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setIsDeleteModalOpen(false);
  };
  const handleSendNotificationClick = () => {
    // Remove duplicate rows based on the id field
    setOpen(true);
  };
  const handleDialogSubmit = async () => {
    const uniqueRowsMap = selectedRowsData.reduce((map, row) => {
      map[row._id] = row;
      return map;
    }, {});

    // Convert the object back to an array of unique rows
    const uniqueRows = Object.values(uniqueRowsMap);

    const notificationMessage = document.getElementById("w3review").value;

    const notificationData = {
      userIds: selectedRowsData,
      message: notificationMessage,
    };

    setOpen(false);
    try {
      const response = await axiosInstance.post(
        "api/admin/send-notification",
        notificationData
      );
      if (response.data.status == true) {
        setOpenSnackbar(true);
        setUpdateStatus({
          message: "Notification sent successfully",
          success: true,
        });
      }
    } catch (error) {
      console.log(error);
      setUpdateStatus({
        message: "Failed to sent Notification",
        success: false,
      });
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleRowSelect = (id) => {
    const updatedData = filteredData.map((row) => {
      if (row._id === id) {
        const updatedRow = { ...row, selected: !row.selected };
        if (updatedRow.selected) {
          // setSelectedRowsData((prevData) => [...prevData, updatedRow]);
          setSelectedRowsData((prevData) => {
            return [...prevData, updatedRow._id];
          });
        } else {
          setSelectedRowsData((prevData) =>
            prevData.filter((item) => item !== id)
          );
        }
        return updatedRow;
      }
      return row;
    });
    setFilteredData(updatedData);
    //  displaySelectedRowsData(); // Update the displayed selected rows
  };
  const displaySelectedRowsData = () => {
    // console.log(selectedRowsData);
    // You can render or use this data as needed
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    // Update the selection state of all rows in filteredData based on selectAll
    const updatedData = filteredData.map((row) => {
      const updatedRow = { ...row, selected: !selectAll };
      if (!selectAll && updatedRow.selected) {
        // Add to selectedRowsData when selecting all
        // setSelectedRowsData((prevData) => [...prevData, updatedRow]);
        setSelectedRowsData((prevData) => {
          return [...prevData, updatedRow._id];
        });
      } else if (selectAll) {
        // Remove from selectedRowsData when deselecting all
        setSelectedRowsData([]);
      }
      return updatedRow;
    });
    setFilteredData(updatedData);
    displaySelectedRowsData(); // Update the displayed selected rows
  };
  const tags = [
    { id: 1, name: "Box to Box" },
    { id: 2, name: "Dead ball specialist" },
    { id: 3, name: "Flair" },
    { id: 4, name: "Engine" },
    { id: 5, name: "Goal machine" },
    { id: 6, name: "Technician" },
    { id: 7, name: "Assist king" },
    { id: 8, name: "Flash" },
    { id: 9, name: "Lethal finishing" },
    { id: 10, name: "Long range" },
    { id: 11, name: "Powerhouse" },
    { id: 12, name: "Tiki-taka" },
    { id: 13, name: "Brick wall" },
    { id: 14, name: "Agile" },
    { id: 15, name: "Maestro" },
    { id: 16, name: "Leader" },
    { id: 17, name: "Playmaker" },
    { id: 18, name: "Aerial play" },
    { id: 19, name: "Reliable" },
    { id: 20, name: "Marksman" },
  ];
  // Function to handle the actual deletion
  const handleDelete = async () => {
    if (deleteItemId) {
      setIsLoading(true); // Start loading
      try {
        await axiosInstance.put(`api/admin/suspend/${deleteItemId}`, {
          suspend,
        });
        // Once the deletion is successful, update the playerData state accordingly
        const updatedPlayerData = await axiosInstance.get("api/admin/getusers");
        setPlayerData(updatedPlayerData.data.users);
        // Close the delete confirmation modal
        handleCloseDeleteModal();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    }
  };
  const updateUserData = async (updatedUser) => {
    const updatedPlayerData = await axiosInstance.get("api/admin/getusers");
    setPlayerData(updatedPlayerData.data.users);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={updateStatus?.success ? "success" : "error"}
          onClose={handleSnackbarClose}
        >
          {updateStatus?.message}
        </Alert>
      </Snackbar>
      {/* Filters  */}
      <div className="absolute top-20 right-[4%]   flex flex-row justify-center gap-4">
        <div>
          <Button
            variant="contained"
            onClick={handleSendNotificationClick}
            sx={{
              backgroundColor: "#0C7240",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#0C7240",
                boxShadow: "none",
              },
            }}
          >
            Send Notifications
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={handleSelectAll}
            sx={{
              backgroundColor: "#0C7240",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#0C7240",
                boxShadow: "none",
              },
            }}
          >
            {selectAll ? "Deselect All" : "Select All"}
          </Button>
        </div>
        <div>
          <TextField
            type="text"
            label="Search by name"
            value={searchQuery}
            onChange={handleSearchInputChange}
            size="small"
          />
        </div>
        <div>
          <TextField
            type="text"
            value={city}
            label="City"
            size="small"
            sx={{ maxWidth: "180px" }}
            select
            onChange={handleCityChange}
          >
            <MenuItem value="Islamabad">Islamabad</MenuItem>
            <MenuItem value="Rawalpindi">Rawalpindi</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </TextField>
        </div>
        <div>
          <TextField
            type="text"
            value={positionFilter}
            size="small"
            sx={{ maxWidth: "180px" }}
            label="Position"
            select
            onChange={handlePositionFilter}
          >
            <MenuItem value="Forward">Forward</MenuItem>
            <MenuItem value="Defender">Defender</MenuItem>
            <MenuItem value="Midfielder">Midfielder</MenuItem>
            <MenuItem value="GoalKeeper">Goalkeeper</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </TextField>
        </div>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          marginX: "auto",
          maxWidth: {
            sm: "100%",
            md: "80%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "80vh",
            sm: "90vh",
          },
          overflowY: "auto",
          position: "relative",
          top: "8rem",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <CircleLoader />
            ) : (
              filteredData.map((data) => (
                <TableRow
                  key={data._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={data.selected || false}
                      onChange={() => handleRowSelect(data._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                  <TableCell align="left">{data.fullName}</TableCell>
                  <TableCell align="left">{data.email}</TableCell>
                  <TableCell align="left">{data.phone_no}</TableCell>
                  <TableCell align="left">{data.role}</TableCell>
                  {/* <TableCell align="left">{data.city}</TableCell> */}
                  <TableCell align="left">
                    {data.isSuspended ? (
                      <Button
                        size="small"
                        variant="text"
                        color="error"
                        onClick={() =>
                          handleDeleteClick(data._id, data.isSuspended)
                        }
                      >
                        Unsuspend
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="text"
                        color="secondary"
                        onClick={() =>
                          handleDeleteClick(data._id, data.isSuspended)
                        }
                      >
                        Suspend
                      </Button>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {data.isVerified ? (
                      <IconButton sx={{ color: "green" }}>
                        <DoneOutlineIcon />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ color: "red" }}>
                        <ClearOutlinedIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <IconButton onClick={() => handleViewClick(data)}>
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "green",
                          }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleEditClick(data)}>
                        <FaRegEdit
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "purple",
                          }}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {selectedPlayer && (
          <PlayerProfileModal
            player={selectedPlayer}
            handleClose={handleCloseModal}
          />
        )}
        {editUser && (
          <EditUserModal
            user={editUser}
            handleClose={handleCloseEditModal}
            handleUpdate={updateUserData}
            tags={tags}
          />
        )}
      </TableContainer>
      {/* Render the delete confirmation modal */}
      {isDeleteModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3"
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "10px",
            backgroundColor: "#fff",
          }}
        >
          <div className="flex flex-col gap-3">
            <h2 className={suspend ? "text-red-700" : "text-green-700"}>
              {suspend ? "Confirm Suspension!" : "Revoke Suspension!"}
            </h2>
            <p className="sm:text-sm">
              {suspend
                ? "Are you sure you want to suspend this account?"
                : "Are you sure you want to revoke the suspension?"}
            </p>
            <div className="flex justify-center gap-3">
              <Button
                variant="contained"
                color={suspend ? "secondary" : "error"}
                size="small"
                onClick={handleDelete}
              >
                {isLoading ? <CircularProgress size={20} /> : "Confirm"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleCloseDeleteModal}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Send Notifications
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>
              Please Enter the notification that you want to send to the{" "}
              <span
                style={{
                  color: "red",
                  fontStyle: "italic",
                  textDecoration: "underline",
                }}
              >
                Selected
              </span>{" "}
              users
            </Typography>
            <textarea
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              style={{
                width: "100%",
                height: "150px",
                padding: "12px 20px",
                boxSizing: "border-box",
                border: "2px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f8f8f8",
                fontSize: "16px",
                resize: "none",
              }}
            ></textarea>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleDialogSubmit} variant="contained">
              Send
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
};
export default TableData;
