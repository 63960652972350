import React, { useContext, useEffect } from "react";
import MatchTable from "../component/MatchTable";
import { AuthContext } from "../auth/ContextAuthentication";
import axiosInstance from "../config/AxiosConfig";

const MatchProfile = () => {
  const { setMatchData } = useContext(AuthContext);
  const headerData = [
    "MatchType",
    "Organizer",
    "Status",
    "GroundName",
    "Date",
    "StartTime",
    "EndTime",
  ];
  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axiosInstance.get("api/admin/allmatches");
        setMatchData(response.data.matches);
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchApi();
  }, [setMatchData]);
  return (
    <>
      <MatchTable headerData={headerData} />
    </>
  );
};

export default MatchProfile;
