// PlayerProfileModal.js
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import axiosInstance from "../config/AxiosConfig";
const PlayerProfileModal = ({ player, handleClose }) => {
  const [playerStats, setPlayerStats] = useState([]);

  useEffect(() => {
    const fetchStatsApi = async () => {
      try {
        const response = await axiosInstance.get(
          `api/admin/stats/${player._id}`
        );
        setPlayerStats(response.data.stats);
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchStatsApi();
  }, [player._id]);

  // Add conditional checks to ensure attributes and tags exist
  const attributes = player.attributes || {};
  const tags = attributes.tags || [];

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return an empty string if date is not available
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format the date as per the user's locale
  };

  return (
    <Dialog open={!!player} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>User Profile</DialogTitle>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "140px",
            height: "140px",
            borderRadius: "50%",
            overflow: "hidden",
            marginBottom: "0.5rem",
          }}
        >
          <img
            src={`https://maidan-s3.s3.us-west-2.amazonaws.com/${player.profile}`}
            alt="User Profile"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          {/* <img src={'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=2000'} alt="User Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> */}
        </div>
      </div>
      <DialogContent
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "1rem",
          }}
        >
          <div>
            <strong>Full Name:</strong> {player.fullName}
          </div>
          <div>
            <strong>User ID:</strong> {player._id}
          </div>
          <div>
            <strong>Email:</strong> {player.email}
          </div>
          <div>
            <strong>Phone:</strong> {player.phone_no}
          </div>
          <div>
            <strong>Role:</strong> {player.role}
          </div>
          <div>
            <strong>IsVerified:</strong>{" "}
            {player.isVerified ? (
              <IconButton sx={{ color: "green" }}>
                <DoneOutlineIcon />
              </IconButton>
            ) : (
              <IconButton sx={{ color: "red" }}>
                <ClearOutlinedIcon fontSize="medium" />
              </IconButton>
            )}
          </div>
          <div>
            <strong>IsSuspended:</strong>{" "}
            {player.isSuspended ? (
              <IconButton sx={{ color: "green" }}>
                <DoneOutlineIcon />
              </IconButton>
            ) : (
              <IconButton sx={{ color: "red" }}>
                <ClearOutlinedIcon fontSize="medium" />
              </IconButton>
            )}
          </div>
          <div>
            <strong>City:</strong> {player.city}
          </div>
          <div>
            <strong>Date of Birth:</strong> {formatDate(player.dob)}
          </div>
        </div>

        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "1rem",
          }}
        >
          {/* <div>
            <strong>User Attributes</strong>
          </div> */}
          <div>
            <strong>Position:</strong> {attributes.position}
          </div>
          <div>
            <strong>Preffered Foot:</strong> {attributes.prefferdFoot}
          </div>
          <div>
            <strong>Age:</strong> {attributes.age}
          </div>
          <div>
            <strong>Height:</strong> {attributes.height}
          </div>
          <div>
            <strong>Weight:</strong> {attributes.weight}
          </div>
          <div>
            <strong>Tags:</strong> {tags.join(", ")}
          </div>
        </div>

        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "1rem",
          }}
        >
          <div>
            <strong>Player Stats</strong>
          </div>
          <div>
            <strong>Played:</strong> {playerStats.played}
          </div>
          <div>
            <strong>No Show:</strong> {playerStats.noShow}
          </div>
          <div>
            <strong>Goals:</strong> {playerStats.goals}
          </div>
          <div>
            <strong>Rating:</strong> {playerStats.ratting}
          </div>
          <div>
            <strong>Most Valuable Player:</strong> {playerStats.mvp}
          </div>
          <div>
            <strong>Win:</strong> {playerStats.win}
          </div>
          <div>
            <strong>Loss:</strong> {playerStats.loss}
          </div>
          <div>
            <strong>Upcomming:</strong> {playerStats.upcomming}
          </div>
          <div>
            <strong>Organized:</strong> {playerStats.organized}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayerProfileModal;
