import React from "react";
import { Circles } from "react-loader-spinner";

const CircleLoader = () => {
  return (
    <div
      style={{
        height: "70vh",
        width: "80vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Circles
        height="120"
        width="120"
        color="#4fa94d"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export default CircleLoader;
