import React, { useContext, useEffect } from "react";
import TableData from "../component/TableData";
import { AuthContext } from "../auth/ContextAuthentication";
import axiosInstance from "../config/AxiosConfig";

const PlayersProfile = () => {
  const { setPlayerData } = useContext(AuthContext);
  const headerData = [
    "",
    "FullName",
    "Email",
    "PhoneNo",
    "Role",
    "IsSuspended",
    "IsVerified",
  ];

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axiosInstance.get("api/admin/getusers");
        setPlayerData(response.data.users);
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchApi();
  }, [setPlayerData]);

  return (
    <>
      <TableData headerData={headerData} />
    </>
  );
};

export default PlayersProfile;
